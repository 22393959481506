import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2c8d73bd = () => interopDefault(import('../pages/campaign-template/index.vue' /* webpackChunkName: "pages/campaign-template/index" */))
const _04817798 = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _22739d06 = () => interopDefault(import('../pages/header/index.vue' /* webpackChunkName: "pages/header/index" */))
const _17846d52 = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _67459582 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _7cc93a5a = () => interopDefault(import('../pages/modal/index.vue' /* webpackChunkName: "pages/modal/index" */))
const _1c2291c0 = () => interopDefault(import('../pages/news-post/index.vue' /* webpackChunkName: "pages/news-post/index" */))
const _6d11cf82 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _de1c2d44 = () => interopDefault(import('../pages/page-template/index.vue' /* webpackChunkName: "pages/page-template/index" */))
const _3af08124 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _f2bc141c = () => interopDefault(import('../pages/scene/index.vue' /* webpackChunkName: "pages/scene/index" */))
const _4c1051b7 = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _28abb074 = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _527091f1 = () => interopDefault(import('../pages/work-activity/index.vue' /* webpackChunkName: "pages/work-activity/index" */))
const _13c0e42c = () => interopDefault(import('../pages/news-post/view/index.vue' /* webpackChunkName: "pages/news-post/view/index" */))
const _3b9a4504 = () => interopDefault(import('../pages/work-activity/map/index.vue' /* webpackChunkName: "pages/work-activity/map/index" */))
const _57a1f74e = () => interopDefault(import('../pages/work-activity/view/index.vue' /* webpackChunkName: "pages/work-activity/view/index" */))
const _6fca7688 = () => interopDefault(import('../pages/campaigns/news-post/_id.vue' /* webpackChunkName: "pages/campaigns/news-post/_id" */))
const _b193b134 = () => interopDefault(import('../pages/work-activity/map/_id.vue' /* webpackChunkName: "pages/work-activity/map/_id" */))
const _04d31131 = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _25a80972 = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "pages/contents/_id" */))
const _72f02816 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign-template",
    component: _2c8d73bd,
    name: "campaign-template"
  }, {
    path: "/form",
    component: _04817798,
    name: "form"
  }, {
    path: "/header",
    component: _22739d06,
    name: "header"
  }, {
    path: "/healthcheck",
    component: _17846d52,
    name: "healthcheck"
  }, {
    path: "/map",
    component: _67459582,
    name: "map"
  }, {
    path: "/modal",
    component: _7cc93a5a,
    name: "modal"
  }, {
    path: "/news-post",
    component: _1c2291c0,
    name: "news-post"
  }, {
    path: "/page",
    component: _6d11cf82,
    name: "page"
  }, {
    path: "/page-template",
    component: _de1c2d44,
    name: "page-template"
  }, {
    path: "/preview",
    component: _3af08124,
    name: "preview"
  }, {
    path: "/scene",
    component: _f2bc141c,
    name: "scene"
  }, {
    path: "/unsubscribe",
    component: _4c1051b7,
    name: "unsubscribe"
  }, {
    path: "/widget",
    component: _28abb074,
    name: "widget"
  }, {
    path: "/work-activity",
    component: _527091f1,
    name: "work-activity"
  }, {
    path: "/news-post/view",
    component: _13c0e42c,
    name: "news-post-view"
  }, {
    path: "/work-activity/map",
    component: _3b9a4504,
    name: "work-activity-map"
  }, {
    path: "/work-activity/view",
    component: _57a1f74e,
    name: "work-activity-view"
  }, {
    path: "/campaigns/news-post/:id?",
    component: _6fca7688,
    name: "campaigns-news-post-id"
  }, {
    path: "/work-activity/map/:id",
    component: _b193b134,
    name: "work-activity-map-id"
  }, {
    path: "/campaigns/:id?",
    component: _04d31131,
    name: "campaigns-id"
  }, {
    path: "/contents/:id?",
    component: _25a80972,
    name: "contents-id"
  }, {
    path: "/",
    component: _72f02816,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
